import {
  OrganizationEntry,
  PersonEntry,
} from "frontend/contentful/schema/semantics";

export function getEntityName(
  entity: PersonEntry | OrganizationEntry | undefined,
): string {
  // Filter undefined
  if (!entity?.fields) return "";

  // Filter Organization Entry
  if ("title" in entity.fields) {
    const organizationEntity = entity as OrganizationEntry;
    return organizationEntity.fields.title ?? "";
  }

  // Narrow down to PersonEntry
  const personEntity = entity as PersonEntry;

  if (personEntity.fields.displayName) {
    return personEntity.fields.displayName;
  }

  return [
    personEntity.fields.firstName,
    personEntity.fields.middleName,
    personEntity.fields.lastName,
    personEntity.fields.nameSuffix,
  ]
    .filter((name) => name) // Removes empty strings
    .join(" ");
}
