import { Fragment, ReactNode } from "react";
import {
  BLOCKS,
  INLINES,
  type Document,
  MARKS,
} from "@contentful/rich-text-types";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import {
  FootnoteEntry,
  LinkEntry,
  SimpleCtaEntry,
  TooltipEntry,
} from "frontend/contentful/schema/primitives";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { Link } from "design-system/components/primitives/link/link";
import {
  ImageEntry,
  VideoEntry,
  WowzaEmbedEntry,
} from "frontend/contentful/schema/semantics";
import { MediaAsset } from "design-system/components/primitives/media-asset/media-asset";
import {
  createMediaAssetProps,
  createRichMediaAssetProps,
} from "../primitives/media-asset.props";
import { FootnoteMarker } from "design-system/components/primitives/footnote/footnotes";
import { Tooltip } from "design-system/components/primitives/tooltip/tooltip";
import { createLinkProps } from "../primitives/link.props";
import {
  AssetRef,
  ContentfulEntry,
  EntryRef,
} from "frontend/contentful/schema/sys";
import { useContentful } from "frontend/hooks/use-contentful";
import { AppUrlEntry } from "frontend/contentful/schema/app";
import { CtaLink } from "design-system/components/primitives/cta-link/cta-link";
import { CtaList } from "design-system/components/primitives/cta-list/cta-list";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { createCtaListProps } from "../primitives/cta-list.props";
import { createTooltipProps } from "../primitives/tooltip.props";
import { resolveAssetUrl } from "frontend/contentful/lib/resolve-asset.url";
import { usePageProps } from "frontend/hooks/use-page-props";
import {
  DataVisualizationEmbedEntry,
  DataWrapperEmbedEntry,
  FlourishEmbedEntry,
  PullQuoteEntry,
} from "frontend/contentful/schema/blocks";
import { PullQuote } from "design-system/components/blocks/pull-quote/pull-quote";
import { createPersonTeaseProps } from "../blocks/teases/person-tease.props";
import { DataVisualizationEmbed } from "design-system/components/blocks/data-visualization-embed/data-visualization-embed";
import { DataWrapperEmbed } from "design-system/components/blocks/data-wrapper-embed/data-wrapper-embed";
import { createComponentHeaderProps } from "../primitives/component-header.props";
import { FlourishEmbed } from "design-system/components/blocks/flourish-embed/flourish-embed";
import { WowzaEmbed } from "design-system/components/primitives/wowza-embed/wowza";

export interface RenderContentfulRichTextOptions extends Options {
  disableParagraphs?: boolean;
  resetHeaders?: "h2" | "h3" | "h4" | "h5";
}

// support shift+enter for line breaks
export function renderBreakLineText(text?: string): ReactNode {
  if (typeof text !== "string") return "";

  const lines = text.split("\n");

  return lines.map((line, i) => {
    return (
      <Fragment key={i}>
        {line}
        {i !== lines.length - 1 && <br />}
      </Fragment>
    );
  });
}

interface RenderContentfulRichTextProps
  extends Partial<RenderContentfulRichTextOptions> {
  document: Document | null | undefined;
}

export function RenderContentfulRichText({
  document,
  ...options
}: RenderContentfulRichTextProps) {
  const { includes } = usePageProps();
  const { createEditAttributes, getEntry, getEntryUrl } = useContentful();

  if (
    !document ||
    !Array.isArray(document?.content) ||
    document.content.length < 1
  ) {
    return null;
  }

  // if is empty paragraph, return null
  const p = document.content[0];

  if (document.content.length === 1 && p?.nodeType === BLOCKS.PARAGRAPH) {
    if (!p.content || p.content.length === 0) return null;

    const node = p.content[0];

    if (p.content.length === 1) {
      if (
        node?.nodeType === "text" &&
        (!node.value || (node.value && node.value.trim() === ""))
      ) {
        return null;
      }
    }
  }

  let footnoteCount = 0;

  const h2Count = document.content.filter((block) => {
    return block.nodeType === BLOCKS.HEADING_2;
  }).length;
  const h3Count = document.content.filter((block) => {
    return block.nodeType === BLOCKS.HEADING_3;
  }).length;
  const h4Count = document.content.filter((block) => {
    return block.nodeType === BLOCKS.HEADING_4;
  }).length;
  const h5Count = document.content.filter((block) => {
    return block.nodeType === BLOCKS.HEADING_5;
  }).length;
  const h6Count = document.content.filter((block) => {
    return block.nodeType === BLOCKS.HEADING_6;
  }).length;

  const currrentHeader: Array<string> = [];
  if (h2Count !== 0) currrentHeader.push("h2");
  if (h3Count !== 0) currrentHeader.push("h3");
  if (h4Count !== 0) currrentHeader.push("h4");
  if (h5Count !== 0) currrentHeader.push("h5");
  if (h6Count !== 0) currrentHeader.push("h6");

  const headers = ["h2", "h3", "h4", "h5", "h6", "h6", "h6", "h6"] as const;
  type Header = (typeof headers)[number];

  const Bold = ({ children }: { children: ReactNode }) => (
    <strong>{children}</strong>
  );
  const Italic = ({ children }: { children: ReactNode }) => <em>{children}</em>;

  const content = documentToReactComponents(document, {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
    },
    renderText: renderBreakLineText,
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        if (!node.data.uri) return null;

        if (node.nodeType === INLINES.HYPERLINK) {
          const href = node.data.uri as string | undefined;
          if (href) return <Link href={href}>{children}</Link>;
        }
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        if (node.nodeType === INLINES.ASSET_HYPERLINK) {
          if (!node.data.target) return null;

          const assetUrl = resolveAssetUrl({
            includes,
            asset: node.data.target as AssetRef,
          });
          if (assetUrl) return <Link href={assetUrl}>{children}</Link>;
        }

        return null;
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        if (!node?.data?.target) return null;

        if (node.nodeType === INLINES.ENTRY_HYPERLINK) {
          const href = getEntryUrl(node?.data?.target as EntryRef<AppUrlEntry>);
          if (href) return <Link href={href}>{children}</Link>;
        }
      },

      [BLOCKS.PARAGRAPH]: (_, children) => {
        if (options?.disableParagraphs) return children;
        return <p>{children}</p>;
      },
      [BLOCKS.HEADING_2]: (_, children) => {
        if (options?.resetHeaders && headers[currrentHeader.indexOf("h2")]) {
          const HeadingLevel =
            options.resetHeaders === "h2"
              ? (headers[currrentHeader.indexOf("h2")]! as Header)
              : options.resetHeaders === "h3"
                ? (headers[currrentHeader.indexOf("h2") + 1]! as Header)
                : options.resetHeaders === "h4"
                  ? (headers[currrentHeader.indexOf("h2") + 2]! as Header)
                  : (headers[currrentHeader.indexOf("h2") + 3]! as Header);
          return (
            <HeadingLevel className="hbs-text-h2">{children}</HeadingLevel>
          );
        }
        return <h2>{children}</h2>;
      },
      [BLOCKS.HEADING_3]: (_, children) => {
        if (options?.resetHeaders && headers[currrentHeader.indexOf("h3")]) {
          const HeadingLevel =
            options.resetHeaders === "h2"
              ? (headers[currrentHeader.indexOf("h3")]! as Header)
              : options.resetHeaders === "h3"
                ? (headers[currrentHeader.indexOf("h3") + 1]! as Header)
                : options.resetHeaders === "h4"
                  ? (headers[currrentHeader.indexOf("h3") + 2]! as Header)
                  : (headers[currrentHeader.indexOf("h3") + 3]! as Header);
          return (
            <HeadingLevel className="hbs-text-h3">{children}</HeadingLevel>
          );
        }
        return <h3>{children}</h3>;
      },
      [BLOCKS.HEADING_4]: (_, children) => {
        if (options?.resetHeaders && headers[currrentHeader.indexOf("h4")]) {
          const HeadingLevel =
            options.resetHeaders === "h2"
              ? (headers[currrentHeader.indexOf("h4")]! as Header)
              : options.resetHeaders === "h3"
                ? (headers[currrentHeader.indexOf("h4") + 1]! as Header)
                : options.resetHeaders === "h4"
                  ? (headers[currrentHeader.indexOf("h4") + 2]! as Header)
                  : (headers[currrentHeader.indexOf("h4") + 3]! as Header);
          return (
            <HeadingLevel className="hbs-text-h4">{children}</HeadingLevel>
          );
        }
        return <h4>{children}</h4>;
      },
      [BLOCKS.HEADING_5]: (_, children) => {
        if (options?.resetHeaders && headers[currrentHeader.indexOf("h5")]) {
          const HeadingLevel =
            options.resetHeaders === "h2"
              ? (headers[currrentHeader.indexOf("h5")]! as Header)
              : options.resetHeaders === "h3"
                ? (headers[currrentHeader.indexOf("h5") + 1]! as Header)
                : options.resetHeaders === "h4"
                  ? (headers[currrentHeader.indexOf("h5") + 2]! as Header)
                  : (headers[currrentHeader.indexOf("h5") + 3]! as Header);
          return (
            <HeadingLevel className="hbs-text-h5">{children}</HeadingLevel>
          );
        }
        return <h5>{children}</h5>;
      },
      [BLOCKS.HEADING_6]: (_, children) => {
        if (options?.resetHeaders && headers[currrentHeader.indexOf("h6")]) {
          const HeadingLevel =
            options.resetHeaders === "h2"
              ? (headers[currrentHeader.indexOf("h6")]! as Header)
              : options.resetHeaders === "h3"
                ? (headers[currrentHeader.indexOf("h6") + 1]! as Header)
                : options.resetHeaders === "h4"
                  ? (headers[currrentHeader.indexOf("h6") + 2]! as Header)
                  : (headers[currrentHeader.indexOf("h6") + 3]! as Header);
          return (
            <HeadingLevel className="hbs-text-h6">{children}</HeadingLevel>
          );
        }
        return <h6>{children}</h6>;
      },
      [BLOCKS.HR]: () => {
        return <hr aria-hidden="true" />;
      },

      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const entry = getEntry(node?.data?.target as ContentfulEntry);

        if (isContentType<LinkEntry>("link", entry)) {
          return <Link {...createLinkProps(entry)} />;
        }

        if (isContentType<FootnoteEntry>("footnote", entry)) {
          footnoteCount++;

          return (
            <FootnoteMarker
              index={footnoteCount}
              editAttributes={createEditAttributes({ entry, fieldId: "text" })}
            />
          );
        }

        if (isContentType<TooltipEntry>("tooltip", entry)) {
          const props = createTooltipProps(entry);
          if (!props?.text) {
            return;
          }

          return <Tooltip {...props} />;
        }
      },

      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = getEntry(node?.data?.target as ContentfulEntry);

        if (isContentType<ImageEntry>("image", entry)) {
          return <MediaAsset {...createRichMediaAssetProps(entry)} />;
        }

        if (isContentType<VideoEntry>("video", entry)) {
          return <MediaAsset {...createRichMediaAssetProps(entry)} />;
        }

        if (isContentType<PullQuoteEntry>("pullQuote", entry)) {
          return (
            <PullQuote
              align={entry.fields.alignment}
              quote={
                <RenderContentfulRichText document={entry.fields.quoteText} />
              }
              attribution={createPersonTeaseProps(entry.fields.attribution)}
              hideQuoteMarks={entry.fields.hideQuoteMarks}
            />
          );
        }

        if (
          isContentType<DataVisualizationEmbedEntry>(
            "dataVisualizationEmbed",
            entry,
          )
        ) {
          return (
            <DataVisualizationEmbed
              header={
                entry.fields.header
                  ? createComponentHeaderProps(entry.fields.header)
                  : undefined
              }
              HeadingLevel="h3"
              embedSourceUrl={entry.fields.embedSourceUrl}
              embedSourceTitle={entry.fields.embedSourceTitle}
              height={entry.fields.height}
              align={entry.fields.alignment}
            />
          );
        }

        if (isContentType<DataWrapperEmbedEntry>("dataWrapperEmbed", entry)) {
          if (!entry.fields.embedId) {
            return null;
          }

          return (
            <DataWrapperEmbed
              header={
                entry.fields.header
                  ? createComponentHeaderProps(entry.fields.header)
                  : undefined
              }
              HeadingLevel="h3"
              embedId={entry.fields.embedId}
              embedSourceTitle={entry.fields.embedSourceTitle}
              height={entry.fields.height}
              align={entry.fields.alignment}
            />
          );
        }

        if (isContentType<FlourishEmbedEntry>("flourishEmbed", entry)) {
          if (!entry.fields.embedSourceUrl) {
            return null;
          }

          return (
            <FlourishEmbed
              headerContent={createComponentHeaderProps(entry.fields.header)}
              HeadingLevel="h3"
              align={entry.fields.alignment}
              embedSourceUrl={entry.fields.embedSourceUrl}
            />
          );
        }

        if (isContentType<WowzaEmbedEntry>("wowzaEmbed", entry)) {
          if (entry.fields.videoPath) {
            const mediaAsset = createMediaAssetProps(entry.fields.media);
            return (
              <WowzaEmbed
                videoPath={entry.fields.videoPath}
                media={mediaAsset?.image}
              />
            );
          }
          return null;
        }

        if (isContentType<SimpleCtaEntry>("simpleCta", entry)) {
          if (entry.fields.links) {
            if (
              entry.fields.links?.length === 1 ||
              entry.fields.linkType === "primary button" ||
              entry.fields.linkType === "secondary button"
            ) {
              const ctaLinkProps = createCtaLinkProps(entry.fields.links[0]);
              if (ctaLinkProps) {
                ctaLinkProps.hideIcon = entry.fields.hideIcon;

                if (entry.fields.linkType) {
                  ctaLinkProps.type = entry.fields.linkType.replace(
                    " ",
                    "-",
                  ) as "link" | "primary-button" | "secondary-button";
                }
              }

              return <CtaLink {...ctaLinkProps} />;
            } else {
              return (
                <CtaList
                  hideIcon={entry.fields.hideIcon}
                  {...createCtaListProps(entry.fields.links)}
                />
              );
            }
          }
        }
      },
    },
    ...options,
  });

  return content;
}
