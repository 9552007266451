import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { PersonTeaseProps } from "design-system/components/primitives/person-tease/person-tease";
import { PersonEntry } from "frontend/contentful/schema/semantics";
import { createPersonImageProps } from "frontend/contentful/components/primitives/media-asset.props";
import { RenderContentfulRichText } from "frontend/contentful/components/utils/render-contentful-rich-text";
import { createSocialMediaLinksProps } from "frontend/contentful/components/universal/social-media-links.props";
import { getEntityName } from "frontend/contentful/components/utils/get-entity-name";
import { useContentful } from "frontend/hooks/use-contentful";

export const createPersonTeaseProps = createContentfulProps<
  PersonEntry,
  PersonTeaseProps
>(({ entry, createEditAttributes }) => {
  const { getPersonLink } = useContentful();
  const name = getEntityName(entry);
  const mediaAsset = createPersonImageProps(entry);

  const props: PersonTeaseProps = {
    type: "Person",
    name: name,
    bio: <RenderContentfulRichText document={entry.fields.bio} />,
    social: createSocialMediaLinksProps(entry),
    link: getPersonLink(entry),
    email: entry.fields.email,
    phone: entry.fields.phone,
    titles: Array.isArray(entry.fields.jobTitles) ? entry.fields.jobTitles : [],
    portrait: mediaAsset?.image,

    editAttributes: {
      name: createEditAttributes({ entry, fieldId: "firstName" }),
      titles: createEditAttributes({ entry, fieldId: "jobTitles" }),
    },
  };

  return props;
});
