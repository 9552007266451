import "./data-wrapper-embed.scss";

import { useEffect } from "react";
import cn from "clsx";
import { Alignment } from "design-system/types/types";
import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";
import { loadScript } from "design-system/hooks/use-script";
import { MediaAsset } from "design-system/components/primitives/media-asset/media-asset";

export interface DataWrapperEmbedProps {
  header?: ComponentHeaderProps;
  align?: Alignment;
  height?: string;
  embedId: string;
  embedSourceTitle?: string;
  HeadingLevel?: "h2" | "h3";
}

export function DataWrapperEmbed({
  header,
  align = "center",
  height,
  embedId,
  embedSourceTitle,
  HeadingLevel = "h2",
}: DataWrapperEmbedProps) {
  const wrapperClass = cn(
    `hbs-global-align-${align}`,
    "hbs-component--data-wrapper-embed",
  );

  useEffect(() => {
    loadScript(`https://datawrapper.dwcdn.net/${embedId}/embed.js`, {
      dataTarget: `#datawrapper-vis-${embedId}`,
    });
  }, [embedId]);

  return (
    <div className={wrapperClass} data-region="cb__data-wrapper-embed">
      <div className="hbs-data-wrapper-embed">
        {header && (
          <div className="hbs-data-wrapper-embed__header">
            <ComponentHeader {...header} HeadingLevel={HeadingLevel} />
          </div>
        )}
        <div
          style={{ minHeight: `${height}px` }}
          id={`datawrapper-vis-${embedId}`}
        >
          <noscript>
            <MediaAsset
              image={{
                src: `https://datawrapper.dwcdn.net/${embedId}/full.png`,
                alt: embedSourceTitle,
              }}
            />
          </noscript>
        </div>
      </div>
    </div>
  );
}
